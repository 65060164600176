<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 云豆管理 </template>
      <template #input>
        <a-input placeholder="请输入姓名" v-model="userName" />
        <a-input placeholder="请输入手机号" v-model="mobile" />

        <a-button type="primary" class="btn" :loading="loading" @click="onSearch()"
          v-hasPermi="['user:list:query']">搜索</a-button>
        <a-button class="all_boder_btn" style="margin-right: 20px" @click="onEmpty()">重置</a-button>
        <!-- <a-button class="all_boder_btn" @click="onAdd">新建</a-button> -->
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table class="table-template" :rowKey="(item) => item.userId" :columns="columns" :data-source="tableData"
        @change="onPage" :loading="loading" :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }">
        <template slot="index" slot-scope="item, row, i">
          {{ (pageNumber - 1) * pageSize + i + 1 }}
        </template>
        <!-- 手机号 -->
        <!-- <template slot="mobile" slot-scope="text">
          {{ text ? $encryption('empty', 'phone', text) : '-' }}
        </template> -->
        <template slot="operation" slot-scope="item">
          <div class="blueText">
            <span @click="goPage(item.userId)">详情</span>
            <!-- <span @click="goPage(item.userId)">修改</span> -->
          </div>
        </template>
      </a-table>
    </div>

    <!-- 云豆新建弹窗展示 -->
    <a-modal v-model="category" width="530px" title="云豆新建" :centered="true" :closable="false">
      <div class="all_content_box">
        <div class="L_R_inner">
          <span class="all_left_name">目标用户：</span>
          <div class="right_Div">
            <a-select style="width: 100%" show-search v-model="currentUserName" mode="combobox" placeholder="请输入姓名"
              :default-active-first-option="false" :show-arrow="false" :filter-option="false" :not-found-content="null"
              @search="handleSearch" @select="handleSelect" @change="handleChange" option-label-prop="label">
              <a-select-option v-for="item in userList" :key="item.userId" :value="item.mobile +
                '/' +
                item.name +
                '/' +
                item.userId
                " :label="item.name">
                <template>{{ item.mobile }} / {{ item.name }}</template>
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">云豆标题：</span>
          <div class="right_Div">
            <a-input class="all_input" placeholder="请输入云豆标题标题" v-model="configueData.title" />
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">云豆说明：</span>
          <div class="right_Div">
            <a-input class="all_input" placeholder="请输入云豆说明" v-model="configueData.detail" />
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">云豆类别：</span>
          <div class="right_Div">
            <a-select class="all_input" placeholder="请选择云豆类别" v-model="configueData.typeCode">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option v-for="item in conifgList" :key="item.id" :value="item.code">{{ item.name
              }}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">云豆来源：</span>
          <div class="right_Div">
            <a-select class="all_input" placeholder="请选择云豆来源" v-model="configueData.source">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option value="1">获得云豆</a-select-option>
              <a-select-option value="2">兑换云豆</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">过期时间：</span>
          <div class="right_Div">
            <a-date-picker class="all_input" v-model="configueData.expireDate" @change="onDateChange"
              format="yyyy-MM-DD" value-format="yyyy-MM-DD" placeholder="选择日期时间">
            </a-date-picker>
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">备注信息：</span>
          <div class="right_Div">
            <a-input class="all_input" placeholder="请输入备注信息" v-model="configueData.remark" />
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="PreventLoad" @click="addEditData()">确认</a-button>
        <a-button @click="addCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "用户姓名",
    align: "center",
    dataIndex: "userName",
    scopedSlots: { customRender: "userName" },
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "剩余总云豆",
    align: "center",
    dataIndex: "remainingPoints",
    scopedSlots: { customRender: "remainingPoints" },
  },
  {
    title: "已使用云豆",
    align: "center",
    dataIndex: "usedPoints",
  },
  {
    title: "已过期云豆",
    align: "center",
    dataIndex: "expiredPoints",
  },
  {
    title: "总获取云豆",
    align: "center",
    dataIndex: "totalPoints",
    scopedSlots: { customRender: "totalPoints" },
  },
  {
    title: "操作",
    align: "center",
    width: "220px",
    scopedSlots: { customRender: "operation" },
  },
];

const detailcolumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "云豆标题",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "云豆明细",
    align: "center",
    dataIndex: "detail",
  },
  {
    title: "获得时间",
    align: "center",
    dataIndex: "changeTime",
    scopedSlots: { customRender: "changeTime" },
  },
  {
    title: "获得方式",
    align: "center",
    dataIndex: "typeName",
  },
  {
    title: "获得云豆",
    align: "center",
    dataIndex: "pointsChange",
  },
  {
    title: "剩余云豆",
    align: "center",
    dataIndex: "remainingPoints",
    scopedSlots: { customRender: "remainingPoints" },
  }, {
    title: "过期时间",
    align: "center",
    dataIndex: "expireDate",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import Derive from "@/components/derive.vue"; // 导出组件

export default {
  // 可用组件的哈希表
  components: { HeaderBox, Derive },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      userName: "",
      mobile: "",
      minPoints: "",
      maxPoints: "",

      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      certifItem: {}, // 操作对象

      // 云豆详情变量
      detailcolumns, // 表头
      detailTableData: [], // 列表数据
      ctotal: 0,
      category: false,
      currUserId: undefined, // 当前详情的userid
      detailloading: false, // 列表加载

      // 用户云豆类目
      conifgList: [],
      configuetype: undefined,
      configueData: {
        typeCode: undefined,
        title: undefined,
        typeName: undefined,
        userId: undefined,
        detail: undefined,
        remark: undefined,
        source: undefined,
        expireDate: undefined,
      },
      defaultConfigData: {
        typeCode: undefined,
        title: undefined,
        typeName: undefined,
        userId: undefined,
        detail: undefined,
        remark: undefined,
        source: undefined,
        expireDate: undefined,
      },
      PreventLoad: false,

      userList: [], // 搜索用户列表
      modelkeyword: "",
      currentUserName: '',
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    // 新建云豆
    onAdd() {
      this.category = true;
    },
    // 选择时间
    onDateChange(x, v) {
      this.configueData.expireDate = v;
    },
    // 重置
    onEmpty() {
      this.userName = "";
      this.mobile = "";
      this.minPoints = "";
      this.maxPoints = "";
      this.pageNumber = 1;
      this.clear = undefined;
      this.getList();
    },
    // 搜索
    onSearch() {
      this.pageNumber = 1;
      this.getList();
    },
    // 详情
    goPage(id) {
      this.$router.push({ path: "/admin/Search/UserIntegralDetail?userId=" + id });
    },
    select(e, v) {
      if (v.length > 0) {
        this.productCode = v[0].productCode;
        this.productType = v[0].productType;
      }
      this.tradeName = v[0].productName;
    },
    modelCancel() {
      this.isExportShow = false;
      this.$refs.export.checkedList();
    },
    getList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/user/points/selManageUserPointsList",
        params: {
          userName: this.userName,
          mobile: this.mobile,
          minPoints: this.minPoints,
          maxPoints: this.maxPoints,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    getConfigueList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/user/points/getPointsConifgList",
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.conifgList = res.data;
        }
      });
    },
    addCancel() {
      this.category = false;
      this.currUserId = undefined;
      this.currentUserName = "";
      this.detailTableData = [];
      this.configueData = this.defaultConfigData;
    },
    // 云豆新增
    addEditData() {
      if (!this.configueData.userId) {
        this.$message.warning("请选择用户信息");
        return
      } else if (!this.configueData.title) {
        this.$message.warning("请填写云豆名称");
        return
      } else if (!this.configueData.detail) {
        this.$message.warning("请填写云豆说明");
        return
      } else if (!this.configueData.typeCode) {
        this.$message.warning("请选择云豆类别");
        return
      } else if (!this.configueData.source) {
        this.$message.warning("请选择云豆来源");
        return
      } else if (!this.configueData.expireDate) {
        this.$message.warning("请选择过期时间");
        return
      }
      this.PreventLoad = true;
      this.$ajax({
        url: "/hxclass-management/user/points/add",
        method: "POST",
        params: this.configueData,
      }).then((res) => {
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.category = false;
          this.addCancel();
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 查询用户列表
    handleSearch(e) {
      this.modelkeyword = e;
      this.userList = [];
      this.getUserList();
    },
    handleSelect(e) {
      let resultArr = e.split("/");
      this.currentUserName = resultArr[1];
      this.configueData.userId = resultArr[2];

    },
    handleChange(e) {
      // console.log("========" + JSON.stringify(e));
    },
    getUserList() {
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        if (!this.modelkeyword) {
          return;
        }
        let params = {
          type: 1,
          name: "",
          mobile: "",
        };
        if (!isNaN(this.modelkeyword)) {
          if (!this.$regular.phone.reg.test(this.modelkeyword)) {
            this.$message.warning(this.$regular.phone.msg);
            return;
          }
          params.mobile = this.modelkeyword;
        } else {
          params.name = this.modelkeyword;
        }
        this.$ajax({
          url: "/hxclass-management/user/info/user/info",
          method: "get",
          params,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.userList = res.data;
            if (!res.data.length) {
              this.$message.error("未搜到相关用户");
            }
          } else {
            this.$message.error(res.message);
          }
        });
      }, 1000);
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getList();
    this.getConfigueList();
  },
  // 生命周期-实例挂载后调用
  mounted() { },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {
    rowSelection() {
      return {
        type: "radio",
        onChange: this.select,
      };
    },
  },
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.time {
  display: inline-block;

  /deep/ .ant-select {
    margin: 0 0px 14px 0 !important;
  }

  /deep/.ant-select-selection--single {
    height: 32px !important;
  }

  /deep/.ant-calendar-picker {
    width: 380px !important;
  }
}

.btn {
  margin-right: 24px;
}

.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

/deep/.right-box {
  padding-bottom: 5px !important;
}

.modalDiv {
  >p {
    font-weight: 500;
    color: #333333;
  }

  .code-item {
    >span {
      display: inline-block;
      min-width: 100px;
      margin: 5px 0;
      margin-right: 15px;
    }
  }
}

/deep/.ant-modal-body {
  overflow: hidden;
}

/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}

/deep/.ant-modal-header {
  border-bottom: none;
}

/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}

/deep/ .ant-modal-body {
  padding: 14px 36px 0px;
}

.table-template {
  /deep/.ant-table {
    border: none;
  }
}

.all_content_box {

  .all_input,
  .ant-calendar-picker {
    width: 340px;
  }

  /deep/ .ant-calendar-picker {
    padding: 0;
  }

  /deep/ .ant-select {
    padding: 0;

    .ant-select-selection--single,
    .ant-select-selection__rendered,
    .ant-select-selection-selected-value {
      height: 40px;
      line-height: 38px;
    }
  }

  /deep/ .ant-calendar-picker-input {
    height: 40px;
    line-height: 38px;
  }
}

/deep/.ant-select-selection--single {
  width: 340px;
}
</style>